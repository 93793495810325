import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: '',
    userName: '',
    email: '',
    mobileNumber: '',
    name: '',
    branch: '',
    group: '',
    isActive: false,
    isLoggedIn: false,
    lastLoginTime: '',
    roleId: '',
    jwToken: '',
    expiresIn: 0,
    expiryDate: ''
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer