import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/Account/authenticate',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/Account/logout',
        method: 'post',
        data
    })
}
export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/Account/reset-password',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/Account/forgot-password',
        method: 'post',
        data
    })
}