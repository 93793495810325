import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiResetPassword, apiSignIn, apiSignOut, apiForgotPassword } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { Notification, toast } from 'components/ui'
import { addSeconds } from 'date-fns'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { entryPath, token, signedIn, expiryDate } = useSelector((state) => state.auth.session)

    // const checkTokenExpiry = (expiryDate) => {
    //     const currentTime = new Date();
    //     // console.log({currentTime, expiryDate});
    //     // console.log(currentTime)
    //     // console.log(new Date(expiryDate).getTime(), 'yeahhh')
    //     // console.log(expiryDate, '====----')
      
    //     // console.log(currentTime.getTime() - new Date(expiryDate).getTime(), 'seeee')
    //     if (expiryDate && currentTime.getTime() < new Date(expiryDate).getTime()) {
    //         console.log(expiryDate, 'EXPIRY DATE ====')
    //         // Token is expired, navigate to login screen
    //         handleSignOut();
    //         navigate(appConfig.unAuthenticatedEntryPath, { replace: true });
    //     }
    // };

    const signIn = async (values) => {
        try {
			const resp = await apiSignIn(values)
            console.log(resp.data.data, 'log in data')
            // console.log(resp, '===_------')
			if (resp?.data?.data) {
				const { jwToken, roleId, expiresIn } = resp.data.data
                console.log(resp.data.data.roles, 'thereeeeeeeeeee')  
                const roles = resp.data.data.roles;
                localStorage.setItem('roles', JSON.stringify(roles));
                const userType = resp.data.data.userType;
                localStorage.setItem('userType', JSON.stringify(userType));           
                const entryPath = getEntryPathByRole(roleId)
                const currentTime = new Date()
                const expiryDate = addSeconds(currentTime, expiresIn)
                // const expiryDate = resp.data.data.expiryDate;
                console.log('expiry date', expiryDate)
				dispatch(onSignInSuccess({ token: jwToken, entryPath: entryPath, expiryDate: expiryDate }))
				dispatch(setUser(resp.data.data))
				const redirectUrl = query.get(REDIRECT_URL_KEY)
                if (redirectUrl) {
                    navigate(redirectUrl)
                } else {
                    navigate(entryPath)
                }
                // checkTokenExpiry(expiryDate);
                return {
                    status: 'success',
                    message: 'successfully signed in'
                }
			} else {
                // return {
                //     status: 'failed',
                //     message: 'An error occured while signing in'
                // }
            }
		} catch (errors) {
            // toast.push(
            //     <Notification
            //         title={"An error occured"}
            //         type='danger'
            //         duration={3500}
            //     >
            //         {errors?.response?.data?.message ||
            //             errors.toString() ||
            //             "An error occured while logging in"}
            //     </Notification>,
            //     {
            //         placement: "top-end"
            //     }
            // )
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }


    const handleSignInReset = ()  => {
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

 const resetPassword = async (values) => {
    try {
        const response = await apiResetPassword(values)
        if (response?.data?.code === 200) {
            return {
                status: 'success',
                message: 'successfully reset password'
            },
            handleSignInReset()
        } else {
            toast.push(
                <Notification
                    title={"An error occured"}
                    type='danger'
                    duration={3500}
                >
                    {response?.data?.message ||
                        "An error occured while resetting password"}
                </Notification>,
                {
                    placement: "top-end"
                }
            )
            return false
        }
    } catch (error) {
        toast.push(
            <Notification
                title={"An error occured"}
                type='danger'
                duration={3500}
            >
                {error?.response?.data?.message ||
                    error.toString() ||
                    "An error occured while resetting password"}
            </Notification>,
            {
                placement: "top-end"
            }
        )
        return false
    }
}

const forgotPassword = async (values) => {
    try {
        const response = await apiForgotPassword(values)
        // console.log(response, 'response===--===')
        if (response?.data?.code === 200) {
            // console.log('i am here --==--==--=')
            return {
                status: 'success',
                message: 'successfully initiated forgot password, kindly check your mail for further reset.'
            }
        } else {
            toast.push(
                <Notification
                    title={"An error occured"}
                    type='danger'
                    duration={3500}
                >
                    {response?.data?.message ||
                        "An error occured while initiating forgot password"}
                </Notification>,
                {
                    placement: "top-end"
                }
            )
            return false
        }
    } catch (error) {
        toast.push(
            <Notification
                title={"An error occured"}
                type='danger'
                duration={3500}
            >
                {error?.response?.data?.message ||
                    error.toString() ||
                    "An error occured while initiating forgot password"}
            </Notification>,
            {
                placement: "top-end"
            }
        )
        return false
    }
}

    const getEntryPathByRole = (role) => {
        switch (role) {
            case "Administrator":
                return appConfig.adminEntryPath
            case "Initiator":
                return appConfig.initEntryPath
            case "Authorizer":
                return appConfig.authEntryPath
            default:
                return appConfig.authenticatedEntryPath
        }
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
        try {
			await apiSignOut()
		    handleSignOut()
		} catch (errors) {
            handleSignOut()
		}
	}
    
    // useEffect(() => {
    //     checkTokenExpiry();
    // }, [expiryDate]);

    return {
        authenticated: token && signedIn,
        entryPath,
        expiryDate,
        signIn,
        signOut,
        resetPassword,
        forgotPassword,
        handleSignInReset
    }
}

export default useAuth