const appConfig = {
    apiPrefix: process.env.REACT_APP_BASEURL,
    authenticatedEntryPath: '/dashboard',
    adminEntryPath: '/pin/initiate',
    initEntryPath: '/pin/initiate',
    authEntryPath: '/pin/authorize/list',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
}

export default appConfig