import { useEffect } from 'react';
import { isBefore } from 'date-fns';
import useAuth from 'utils/hooks/useAuth'

const SessionCheck = () => {
    const { authenticated, expiryDate, signOut } = useAuth()

    const checkSessionExpiration = () => {
        if (authenticated) {
            let today = new Date();
            
            // Check if the user is authenticated and the current time is before the expiry time
            if (isBefore(expiryDate, today)) {
                signOut()
            }
        }
    }

    useEffect(() => {
        let myInterval = setInterval(checkSessionExpiration, (5000)); // Check every 15 seconds
        return () => clearInterval(myInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated])

    return null
}

export default SessionCheck