import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        entryPath: '',
        signedIn: false,
        expiryDate: '',
        passwordReset: true
    },
	reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.entryPath = action.payload.entryPath
            state.token = action.payload.token
            state.expiryDate = action.payload.expiryDate
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.entryPath = ''
            state.expiryDate = ''
        },
        onResetPasswordSuccess: (state, action) => {
            state.passwordReset = true
            state.entryPath = action.payload.entryPath
            state.token = action.payload.token
            state.expiryDate = action.payload.expiryDate
        },
        setToken: (state, action) =>  {
            state.token = action.payload
        }
	},
})

export const { onSignInSuccess, onSignOutSuccess, setToken, passwordReset } = sessionSlice.actions

export default sessionSlice.reducer